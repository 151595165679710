import React from 'react';
import {
  HashRouter,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import Closed from './pages/Closed';

function Routing() {
  return (
    <HashRouter>
      <Switch>
        <Route path="/" render={Closed} />
        <Redirect from="*" to="/" />
      </Switch>
    </HashRouter>
  );
}

export default Routing;
