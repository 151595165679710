import React from 'react';
import '../components/AuthForm/index.less';

function Closed() {
  return (
    <div className="Login_wrapper">
      <div className="Login_inner">
        <div>
          <div>
            <img
              src="/logo_with_background.png"
              alt="billy birthday logo"
              className="Login_logo"
            />
          </div>
          <div>
            Hey 👋
            <br />
            <br />
            <b>Billy Birthday Affiliate Program</b>
            {' is now closed. It has merged with a broader product, BuddiesHR. '}
            <br />
            <br />
            <a href="https://partner.buddieshr.com/#/auth/register">Learn more about the BuddiesHR Affiliate Program.</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Closed;
